<template>
  <div v-if="form.orders != ''">
    <b-row>
      <b-col>
        <TotalOrdersWidget :orders="form.orders"></TotalOrdersWidget>
      </b-col>
      <b-col>
        <CancelledOrdersWidget :orders="form.orders"></CancelledOrdersWidget>
      </b-col>
      <b-col>
        <AcceptedOrdersWidget :orders="form.orders"></AcceptedOrdersWidget>
      </b-col>
    
    </b-row>
    <b-row>
      <b-col>
        <OrdersTableWidget
          :orders="form.orders"
          :title="'Today Orders'"
          @update="updateOrders"
        ></OrdersTableWidget>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <center><h2 class="font-poppins">No Orders for Today</h2></center>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TotalOrdersWidget from "@/view/pages/orders/components/TotalOrdersWidget.vue";
import AcceptedOrdersWidget from "@/view/pages/orders/components/AcceptedOrdersWidget.vue";
import CancelledOrdersWidget from "@/view/pages/orders/components/CancelledOrdersWidget.vue";
import OrdersTableWidget from "@/view/pages/orders/components/OrdersTableWidget.vue";
import { ORDERS } from "@/core/services/store/sales.module";
import { mapGetters } from "vuex";

export default {
  name: "TodayOrders",
  computed: {
    ...mapGetters(["currentUser", "pending", "getDate"])
  },
  components: {
    TotalOrdersWidget,
    AcceptedOrdersWidget,
    CancelledOrdersWidget,
    OrdersTableWidget
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Orders" },
      { title: "Today Orders" }
    ]);

    // look for current user
    if (this.currentUser.token !== "") {
      // call method to get orders
      this.fetchOrders();
    }
  },

  data() {
    return {
      form: {
        orders: []
      }
    };
  },
  methods: {
    updateOrders() {
      this.fetchOrders();
    },
    fetchOrders() {
      this.$store
        .dispatch(ORDERS, {
          date: this.getDate,
          pending: 1,
          acknowledged: 1,
          ready: 1
        })
        // go to which page after successfully login
        .then(resp => {
          // this.form.orders = resp.data;
          let temp = [];
          for (let index = 0; index < resp.data.length; index++) {
            temp.push(resp.data[index]);
          }
          if (this.form.orders.length !== temp.length) {
            var audio = new Audio(
              process.env.VUE_APP_API_BASE_URL+"beep.mp3"
            );
            audio.play();
          }
          this.form.orders = [];
          this.form.orders = temp;
          temp = [];
          if (this.$route.name == "today") {
            setTimeout(this.fetchOrders, 20000);
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
